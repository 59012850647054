<template>
  <div
      class="option"
  >
      <checkbox
        :checked="option.checked"
        :disabled="disabled"
        @change="change"
      >
          <div class="question-option__text" v-html="description"></div>
          <div class="question-option__overlay"></div>
      </checkbox>
  </div>
</template>

<script>
import Checkbox from 'element-ui/lib/checkbox'

export default {
  name: 'Question',
  components: {
    Checkbox
  },
  props: {
    option: {
      type: Object
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    description() {
      return this.option.imageUrl ?
        `<img class="question-option" src="${this.option.imageUrl}" />`
        : this.option.description;
    }
  },
  methods: {
    change(value) {
      this.$emit('change', {
        ...this.option,
        checked: value
      });
    }
  }
}
</script>

<style lang="scss">
.option {
  margin-bottom: .5rem;

  > label {
    display: flex;
  }
}

.question-option {
  min-width: 80px;
  max-width: 80px !important;
}

.question-option__text {
  white-space: normal;
}

.el-checkbox__input {
  display: flex;
  align-items: center;
}

.el-checkbox__label {
  font-size: 16px;
}

.question-option__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.option {
  &.wrong {
    background-color: rgba(168, 37, 29, 0.1);
  }

  &.correct {
    background-color: rgba(20, 117, 25, 0.1);
  }

  .el-checkbox__label {
    color: black !important;
  }
}

</style>
