<template>
  <div>


    <VueMarkdown class="question-description" :class="{ 'question-description-signs': hasSignImage }">
    {{ description }}
    </VueMarkdown>

    <template v-if="displayMark">
      <span v-if="submitted && isCorrect">✅</span>
      <span v-if="submitted && !isCorrect">❌</span>
    </template>

    <div
     class="options"
    >
      <question-option
        v-for="option in question.options"
        :key="option._id"
        :option="option"
        :disabled="submitted"
        @change="change"
        :class="{
          'correct': submitted && !!option.is_correct,
          'wrong': submitted && !option.is_correct,
          'wrong-answer': submitted && option.checked && option.checked !== !!option.is_correct,
          'correct-answer': submitted && option.checked && option.checked === !!option.is_correct
        }"
      >
      </question-option>

    </div>
  </div>
</template>

<script>
import VueMarkdown from 'vue-markdown';

import QuestionOption from './QuestionOption';

const convertBlocks = (blocks, position, additional) => {
  const positionText = position ? (position + '. ') : '';
  return `${positionText}` +
  [
    blocks.map(block => {
      if (block._type === 'image' || !block.children) {
        return `![](${block.imageUrl})`;
      }

      // if it's not a text block with children or image,
      // return nothing
      if (block._type !== 'block' || !block.children) {
        return '';
      }

      // loop through the children spans, and join the
      // text strings
      return block.children.map(child => child.text).join('')
    }),

    ...additional
  ]
  // join the parapgraphs leaving split by two linebreaks
  .join('\n\n');
}

export default {
  name: 'Question',
  components: {
    VueMarkdown,
    QuestionOption
  },
  props: {
    question: {
      type: Object
    },
    position: {
      type: Number
    },
    submitted: {
      type: Boolean
    },
    displayMark: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    hasSignImage() {
      return (this.question.imageUrl || '').indexOf('/signs/') > -1
    },
    description() {
      console.log(convertBlocks(
        this.question.description,
        this.position,
        (this.question.imageUrl ? [`![](${this.question.imageUrl})`] : [])
      ))
      return convertBlocks(
        this.question.description,
        this.position,
        (this.question.imageUrl ? [`![](${this.question.imageUrl})`] : [])
      );
    },
    isCorrect() {
      const correct = this.question.options.every((option) => {
        return (option.checked === !!option.is_correct)
        || (!option.checked && !option.is_correct);
      })

      return correct;
    }
  },
  methods: {
    change(value) {
      this.$emit('change', {
        question: this.question,
        option: value
      });
    }
  }
}
</script>

<style lang="scss">
.options {
  @media only screen and (min-width: 1200px) {
    display: flex;

    .option {
      margin-right: 1rem;
    }
  }
}

.question-description {
  img {
    @media only screen and (max-width: 600px) {
      width: 100%;
    }
  }

  &.question-description-signs {
    img {
      width: 80px;
    }
  }
}

</style>
